import type {
  IDateFilterParams,
  IDictionaryFilterParams,
  IDictionaryTreeFilterParams,
  FilterComponentParams,
  ISortFilterParams,
} from '@/common/models/filters/filter-model';
import { FilterTypeEnum } from '@/common/enums/filterType.enum';
import type { IProjectFilters } from '@/views/srm/projects/api/models/responses/projectFilters.model';
import { ProjectsFiltersRequestEnum } from '@/views/srm/projects/api/models/enums/projectsFilters.enum';
import { ProjectsOrderByEnum } from '@/views/srm/projects/api/models/enums/projectsOrderBy.enum';

export function mapProjectsFilters (
  filters: IProjectFilters,
  parentRef: unknown,
  skipIds?: Array<ProjectsFiltersRequestEnum>,
): Array<FilterComponentParams> {
  const startOfCollecting: ISortFilterParams = {
    id: ProjectsFiltersRequestEnum.StartOfCollecting,
    type: FilterTypeEnum.Sort,
    isQueryFilter: true,
    groups: {
      groups: [
        {
          title: 'По дате начала сбора предложений',
          filedName: 'Начало сбора предложений',
          values: [
            {
              viewValue: 'Сначала более ранние даты',
              value: {
                orderBy: ProjectsOrderByEnum.offerStart,
                descending: false,
              },
            },
            {
              viewValue: 'Сначала более поздние даты',
              value: {
                orderBy: ProjectsOrderByEnum.offerStart,
                descending: true,
              },
            },
          ],
        },
        {
          title: 'По дате окончания сбора предложений',
          filedName: 'Окончание сбора предложений',
          values: [
            {
              viewValue: 'Сначала более ранние даты',
              value: {
                orderBy: ProjectsOrderByEnum.offerEnd,
                descending: false,
              },
            },
            {
              viewValue: 'Сначала более поздние даты',
              value: {
                orderBy: ProjectsOrderByEnum.offerEnd,
                descending: true,
              },
            },
          ],
        },
        {
          title: 'По дате изменений',
          filedName: 'Дата изменения',
          values: [
            {
              viewValue: 'Сначала новые',
              value: {
                orderBy: ProjectsOrderByEnum.updatedDate,
                descending: true,
              },
            },
            {
              viewValue: 'Сначала старые',
              value: {
                orderBy: ProjectsOrderByEnum.updatedDate,
                descending: false,
              },
            },
          ],
        },
      ],
    },
    modelValue: {
      descending: false,
      orderBy: ProjectsOrderByEnum.offerEnd,
    },
    parentRef: parentRef,
  };

  startOfCollecting.groups.groups = startOfCollecting.groups.groups.filter((item) => !!item);

  const projectSMethod: IDictionaryFilterParams = filters.projectMethods?.length && {
    id: ProjectsFiltersRequestEnum.ProjectMethods,
    title: 'Способ проведения',
    type: FilterTypeEnum.Dictionary,
    parentRef: parentRef,
    label: 'Способ проведения',
    items: filters.projectMethods,
    valueKey: 'code',
    viewValueKey: 'name',
    wrapDisabled: true,
    width: 255,
    maxHeight: 176,
  };

  const businessEntityFilter: IDictionaryFilterParams = filters.businessEntities?.length && {
    id: ProjectsFiltersRequestEnum.BusinessEntities,
    parentRef,
    type: FilterTypeEnum.Dictionary,
    title: 'Бизнес-единица',
    search: true,
    searchPlaceHolder: 'Введите название',
    rightLabelKey: 'shortName',
    label: 'БЕ',
    items: filters.businessEntities,
    valueKey: 'id',
    viewValueKey: 'name',
    width: 333,
    maxHeight: 288,
  };

  const nomenclatureGroupsFilter: IDictionaryTreeFilterParams = filters.nomenclatureGroups?.length && {
    id: ProjectsFiltersRequestEnum.NomenclatureGroups,
    parentRef: parentRef,
    type: FilterTypeEnum.DictionaryTree,
    title: 'Группы номенклатуры',
    label: 'Группы номенклатуры',
    items: filters.nomenclatureGroups || [],
    valueKey: 'code',
    rightLabelKey: 'code',
    parseCodeKey: 'code',
    viewValueKey: 'name',
    search: true,
    searchPlaceHolder: 'Введите код или название группы номенклатуры',
    searchByStart: true,
    maxHeight: 364,
  };

  const stageType: IDictionaryFilterParams = filters.stageTypes?.length && {
    id: ProjectsFiltersRequestEnum.StageTypes,
    title: 'Этап',
    label: 'Этап',
    type: FilterTypeEnum.Dictionary,
    parentRef: parentRef,
    items: filters.stageTypes,
    valueKey: 'code',
    viewValueKey: 'name',
    wrapDisabled: true,
    width: 246,
  };

  const stageStatuses: IDictionaryFilterParams = filters.stageStatuses?.length && {
    id: ProjectsFiltersRequestEnum.StageStatuses,
    title: 'Статус этапа',
    label: 'Статус этапа',
    type: FilterTypeEnum.Dictionary,
    parentRef: parentRef,
    items: filters.stageStatuses,
    valueKey: 'code',
    viewValueKey: 'name',
    maxHeight: 176,
    width: 246,
  };

  const isOpen: IDictionaryFilterParams = {
    id: ProjectsFiltersRequestEnum.isOpen,
    title: 'Тип',
    label: 'Тип',
    type: FilterTypeEnum.Dictionary,
    parentRef: parentRef,
    items: [
      {
        id: true,
        name: 'Открытый',
      },
      {
        id: false,
        name: 'Закрытый',
      },
    ],
    valueKey: 'id',
    viewValueKey: 'name',
    width: 255,
    maxHeight: 68,

  };

  const supplierOfferStatus = {
    id: ProjectsFiltersRequestEnum.STAGE_SUPPLIER_PARTICIPATION_STATUSES,
    type: FilterTypeEnum.Dictionary,
    parentRef: parentRef,
    label: 'Статус участия',
    title: 'Статус участия',
    items: filters.stageSupplierParticipationStatuses,
    valueKey: 'code',
    viewValueKey: 'name',
    width: 255,
    maxHeight: 248,
  };

  const createEndDate: IDateFilterParams = {
    id: ProjectsFiltersRequestEnum.OfferEnd,
    type: FilterTypeEnum.Date,
    parentRef: parentRef,
    label: 'Окончание сбора предложений',
    modelValue: [],
    titleKey: 'title',
    items: [
      {
        title: 'Дата окончания сбора предложений',
      },
    ],
    width: 368,
    needHours: false,
    datePickerWidth: 175,
  };

  return [
    startOfCollecting,
    projectSMethod,
    stageStatuses,
    stageType,
    isOpen,
    createEndDate,
    supplierOfferStatus,
    nomenclatureGroupsFilter,
    businessEntityFilter,
  ].filter((filterItem) => !!filterItem && !skipIds?.includes(filterItem.id as ProjectsFiltersRequestEnum));
}
