import type { RouteRecordRaw } from 'vue-router';
import HelpPage from '@/common/components/static/HelpPage.vue';
import StaticPage from '@/common/components/static/StaticPage.vue';
import { accountSettingsRoutes } from '@/views/srm/account-settings/routes';
import { projectRoutes } from '@/views/srm/projects/supplierProjectsRoutes';
import { PermissionsEnum } from '@/common/enums/permissions.enum';

export const appRoutes: RouteRecordRaw[] = [
  {
    path: 'supplier-profile',
    component: () => import('./supplier-profile/SupplierProfile.vue'),
    meta: {
      neededPermissions: [PermissionsEnum.SupplierProfileView],
    },
  },
  {
    path: 'supplier-profile-fill',
    component: () => import('./supplier-profile-fill/SupplierProfileFill.vue'),
  },
  {
    path: 'supplier-profile-fill/proceed',
    component: () => import('./supplier-profile-fill/enter/SupplierProfileFillProcessPage.vue'),
  },
  {
    path: 'account-settings',
    component: () => import('./account-settings/AccountSettings.vue'),
    props: {
      title: 'Профиль',
    },
    children: accountSettingsRoutes,
    meta: {
      neededPermissions: [PermissionsEnum.SupplierUsersProfileEdit],
    },
  },
  {
    path: 'supplier-profile/questionnaire/view/:id',
    component: () => import('./questionnaire-supplier/QuestionnaireSupplier.vue'),
    meta: {
      neededPermissions: [PermissionsEnum.SupplierKycEdit],
    },
  },
  {
    path: 'projects',
    component: () => import('@/views/srm/projects/Projects.vue'),
    name: 'projects',
    props: {
      title: 'Проекты',
    },
    children: projectRoutes,
    meta: {
      neededPermissions: [PermissionsEnum.SupplierProjectList],
    },
  },
  {
    path: 'projects/view/:id',
    component: () => import('@/views/srm/project-view/ProjectViewPage.vue'),
    name: 'projectPage',
    meta: {
      neededPermissions: [PermissionsEnum.SupplierProjectView],
    },
  },
  {
    path: 'questionnaires',
    component: () => import('@/views/srm/questionnaires/Questionnaires.vue'),
    name: 'questionnaires',
    props: {
      title: 'Анкеты',
    },
    meta: {
      neededPermissions: [PermissionsEnum.SupplierKycListView],
    },
  },
  {
    path: 'questionnaires/view/:id',
    component: () => import('./questionnaire-supplier/QuestionnaireSupplier.vue'),
    name: 'questionnaires-view',
    props: {
      neededPermissions: [PermissionsEnum.SupplierKycListEditCategory],
    },
  },
  {
    path: 'help',
    component: HelpPage,
    props: {
      imageUrl: '/images/helpImg.jpg',
      containerWidth: '505px',
    },
    name: 'help',
  },
  {
    path: 'access-denied',
    component: StaticPage,
    props: {
      imageUrl: '/images/accessDeniedImg.jpg',
      mainText: 'Для просмотра недостаточно полномочий',
      text: 'Для просмотра информации недостаточно прав \nдоступа. Обратитесь к администратору вашего \nаккаунта или в поддержку.',
      containerWidth: '422px',
    },
    name: 'access-denied',
  },
];
