<script setup lang="ts">
import Filter from '@/common/components/filters/Filter.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import { ref, watch, computed, withDefaults, defineProps, defineEmits } from 'vue';
import type { IDictionaryValue } from '@/common/models/filters/dictionary-value';
import RadioGroup from '@/ui-kit/components/RadioGroup.vue';
import Checkbox from '@/ui-kit/components/Checkbox.vue';
import TextField from '@/ui-kit/components/TextField.vue';
import { IDictionaryFilterParams } from '@/common/models/filters/filter-model';
import type { IRadioButton } from '@/ui-kit/models/radioGroup.model';
import { SEARCH_DEBOUNCE_DEFAULT } from '@/common/constants/timingDefaults.const';
import { debounce } from 'lodash-es';

const MAX_CLIENT_HEIGHT: number = 168;
const props = withDefaults(defineProps<IDictionaryFilterParams>(), {
  width: 247,
  confirmButtonText: 'Применить',
  maxHeight: MAX_CLIENT_HEIGHT,
});

const emits = defineEmits<(e: 'update:modelValue', value?: unknown[] | {userIds: string[], isCurrentUser: boolean}) => void>();

const isOpened = ref<boolean>(false);
const contentVisible = ref<boolean>(true);
const viewValue = ref<string>('');
const searchValue = ref<string>('');
const value = ref<unknown[]>([]);
const filteredItems = ref<IDictionaryValue[]>(props.items);
const radioGroupsValue = ref<IRadioButton>(props.radio ? props.items[0] : null);
const isCurrentUserSelected = ref<boolean>(null)
const scrollBarOptions = ref<{
  wheelPropagation: boolean;
  scrollYMarginOffset: number;
  suppressScrollY: boolean;
}>({
  wheelPropagation: false,
  scrollYMarginOffset: 5,
  suppressScrollY: true,
});
const containerRef = ref(null);

const isSelectedAll = computed<boolean>(() =>
  (getItems() || []).every(
    (item) => !!value.value?.find((valueItem) => valueItem === item[props.valueKey]),
  ),
);

function checked(item: IDictionaryValue): boolean {
  const found = value.value.find((valueItem) => valueItem === item[props.valueKey]);
  return found !== null && found !== undefined;
}

function onValueUpdate(item: IDictionaryValue, checked: boolean): void {
  if (props.radio) {
    value.value = [item[props.valueKey]];
  } else {
    if (checked) {
      value.value.push(item[props.valueKey]);
    } else {
      value.value = value.value?.filter((valueItem) => valueItem !== item[props.valueKey]);
    }
  }
}

async function confirm(): Promise<void> {
  let isUpdateConfirmed = true;

  if (props.updateConfirmCallback) {
    isUpdateConfirmed = await props.updateConfirmCallback();
  }

  if (!isUpdateConfirmed) {
    return;
  }

  if(props.isCurrentUserNeed) {
    emits('update:modelValue', { userIds: value.value ? [...value.value] : [], isCurrentUser: isCurrentUserSelected.value });
    value.value ? setViewValue(value.value) : null
  } else if (props.radio) {
    emits('update:modelValue', value.value?.length ? value.value[0] : null);
  } else {
    emits('update:modelValue', [...value.value]);
    setViewValue(value.value);
  }
  isOpened.value = false;
}

function setViewValue(values: unknown[]): void {
  const selectedItems = [isCurrentUserSelected.value ? 'Текущий пользователь' : null,
    ...getItems().filter((item) => values.includes(item[props.valueKey])).map((item) => item[props.viewValueKey])].filter(x => !!x) as string[];
  if (!selectedItems?.length) {
    viewValue.value = '';
  } else if (selectedItems.length === 1) {
    viewValue.value = selectedItems[0];
  } else {
    viewValue.value = '' + selectedItems.length;
  }
}

function viewValueUpdated(newViewValue: string): void {
  if (!newViewValue) {
    value.value = [];
    viewValue.value = '';
    emits('update:modelValue', []);
  }
}

function onCheckAll(): void {
  if (isSelectedAll.value) {
    value.value = [];
    return;
  }

  value.value = [...(getItems() || []).map((item) => item[props.valueKey])];
}

function getItems(): IDictionaryValue[] {
  const providerItems = props.provider ? filteredItems.value || [] : [];
  const baseItems = toArray(props.items);
  return props.provider ? providerItems : baseItems;
}

function updatePerfectScroll(): void {
  if (containerRef.value?.offsetHeight !== 0 && containerRef.value?.firstElementChild) {
    const containerHeight = containerRef.value.offsetHeight;
    scrollBarOptions.value.suppressScrollY = containerHeight <= MAX_CLIENT_HEIGHT;
  }
}

const debouncedSearchResults = debounce(updateFilteredItems, SEARCH_DEBOUNCE_DEFAULT);

async function updateFilteredItems(value: string): Promise<void> {
  if (props.provider) {
    filteredItems.value = await props.provider(value);
  } else {
    filteredItems.value = filterItems(value);
  }
}

function filterItems(value: string): IDictionaryValue[] {
  if (props.items) {
    return value
      ? props.items.filter((item) =>
        item[props.viewValueKey]?.toLowerCase().includes(value.toLowerCase()),
      )
      : props.items;
  }
  return [];
}

function toArray<T>(value: T | T[]): T[] {
  if (value === null || value === undefined) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}

watch(
  () => searchValue.value,
  (newValue: string, oldValue: string) => {
    if (newValue.length >= MIN_QUERY_CHARS || oldValue.length >= MIN_QUERY_CHARS) {
      debouncedSearchResults(newValue);
    }
  },
);

watch(
  () => isOpened.value,
  (newValue) => {
    if (!newValue) {
      contentVisible.value = false;
      searchValue.value = '';
      setTimeout(() => {
        contentVisible.value = true;
      });
    }

    updatePerfectScroll();
  },
);

watch(
  () => props.modelValue,
  (newValue) => {
    if (props.isCurrentUserNeed) {
      value.value = (props.modelValue as unknown)?.userIds || [];
      isCurrentUserSelected.value = (props.modelValue as unknown)?.isCurrentUser || false;
    } else {
      value.value = toArray(newValue);
    }
    if (props.radio && props.items?.length) {
      radioGroupsValue.value = newValue !== null ? (props.items as Array<IRadioButton>).find((f) => f.value === newValue) : props.items[0];
    }
    setViewValue(value.value)
  },
);

watch(
  () => props.provider,
  () => {
    props.provider &&
    props.provider(searchValue.value).then((items: IDictionaryValue[]) => {
      filteredItems.value = items;
      setViewValue(value.value);
    });
  },
  { immediate: true },
);

watch(
  () => props.items,
  () => {
    props.items && (filteredItems.value = props.items);
  },
);
watch(
  () => props.radio,
  () => {
    props.radio && (value.value = (props.items?.length ? [props.items[0][props.valueKey]] : []));
  },
  { immediate: true },
)
</script>

<template>
  <Filter
    v-model:opened="isOpened"
    :label="label"
    :parent-ref="parentRef"
    :icon="IconPathEnum.NavigationArrowDown20PxSvg"
    :view-value="viewValue"
    :update-confirm-callback="updateConfirmCallback"
    :disabled="disabled"
    @update:view-value="viewValueUpdated($event)"
  >
    <div
      v-if="contentVisible"
      class="d-flex flex-column"
      :class="{'text-nowrap' : !radio}"
      :style="{ width: width + 'px' }"
    >
      <div class="mm-body-medium-m mb16">{{ title }}</div>
      <TextField
        v-if="search"
        v-model="searchValue"
        :placeholder="searchPlaceHolder"
        :icon-path="IconPathEnum.NavigationSearchSvg"
        size="small"
        class="text-field-no-padding-40 mb20"
      />
      <Checkbox
        v-if="hasSelectAllButton"
        :model-value="isSelectedAll"
        class="select-all-check"
        @update:model-value="onCheckAll"
      >
        Выбрать все
      </Checkbox>
      <div
        class="checkboxes-container d-flex flex-column"
        :style="{ 'max-height': maxHeight + 'px' }"
      >
        <RadioGroup
          v-if="radio"
          v-model="radioGroupsValue"
          :buttons="items as Array<IRadioButton>"
          column
          @update:model-value="onValueUpdate(radioGroupsValue, $event)"
        />
        <PerfectScrollbar
          watch-options
          :options="scrollBarOptions"
        >
          <div
            v-if="filteredItems?.length"
            ref="containerRef"
            class="checkboxes-container-wrapper"
          >
            <div
              v-if="isCurrentUserNeed"
              class="current-user-checkbox"
            >
              <Checkbox v-model="isCurrentUserSelected">
                {{ localCurrentUserName }}
              </Checkbox>
              <hr>
            </div>
            <div v-if="!radio">
              <Checkbox
                v-for="item in filteredItems"
                :key="item[valueKey]"
                :model-value="checked(item)"
                class="checkbox-margin"
                @update:model-value="onValueUpdate(item, $event)"
              >
                <div class="checkbox-margin--content">
                  <div class="checkbox-label">
                    <span>
                      {{ item[viewValueKey] }}
                    </span>
                    <span>
                      {{ item[rightLabelKey] || '' }}
                    </span>
                  </div>
                  <div
                    v-if="additionalLabelKey && item[additionalLabelKey]"
                    class="additional-info"
                  >
                    {{ item[additionalLabelKey] }}
                  </div>
                </div>
              </Checkbox>
            </div>
          </div>
          <span v-else>Нет данных</span>
        </PerfectScrollbar>
      </div>

      <button
        class="btn btn-primary d-flex justify-content-center mt24"
        @click="confirm"
      >
        {{ confirmButtonText }}
      </button>
    </div>
  </Filter>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.checkboxes-container {

  &-grid {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    gap: 12px 40px;
  }
}

:deep(.radio-group__item__text) {
  max-width: 195px;
}

.current-user-checkbox {
  padding-bottom: 12px;

  hr {
    opacity: 1;
    color: $dark-gray;
    width: calc(100% - 16px);
    margin: 13px 0 0 0;
  }
}

.checkbox-margin {
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  flex-wrap: nowrap;

  &--content {
    width: 100%;
    .checkbox-label {
      display: flex;
      align-items: center;
      width: 95%;
      justify-content: space-between;

      span:not(:first-child):last-child {
        color: $light-green;
        width: 20%;
        text-align: right;
      }
    }
    .additional-info {
      color: $light-green;
      font-size: 12px;
      line-height: 16px;
      width: 204px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.checkbox-width {
  width: fit-content;
  flex-wrap: nowrap;
}

.select-all-check {
  padding-bottom: 12px;
  border-bottom: 1px solid $gray-200;
  margin-bottom: 12px;
}
</style>
